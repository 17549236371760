import React, { Component } from "react";
import styled, { ThemeProvider } from "styled-components";
import { lightTheme, darkTheme } from './themes';
import GlobalStyle from './GlobalStyle';
import picture from "./picture.jpg";
import { ButtonLinkedIn, ButtonGithub, ButtonStackOverflow } from "./Buttons";

const Container = styled.div`
  /* border: 1px solid red; */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  margin-bottom: 6rem;
  @media (max-width: 800px) {
    flex-direction: column;
    text-align: center;
  }
`;

const Picture = styled.div`
  /* border: 1px solid red; */
  margin-right: 4.5rem;
  & img {
    border-radius: 100%;
    width: 11rem;
  }
  @media (max-width: 800px) {
    margin-right: 0;
    margin-bottom: 2rem;
  }
`;

const ContentBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ContentBody = styled.div`
  /* border: 1px solid green; */
`;

const NameText = styled.h1`
  font-size: 1.2em;
  font-weight: bold;
  color: ${({ theme }) => theme.textPrimary};
  margin: 0 0 0.4em 0;
`;

const FunctionText = styled.h2`
  font-size: 1.2em;
  font-weight: 400;
  color: ${({ theme }) => theme.textSecondary};
  margin: 0;
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* border: 2px solid purple; */
  padding-right: 4rem;
  margin-top: 2rem;
  @media (max-width: 800px) {
    padding-right: 0;
    justify-content: space-around;
  }
`;

// const Text
export default class Page extends Component {
  render() {
    const useDarkTheme = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    return (
      <ThemeProvider theme={useDarkTheme ? darkTheme : lightTheme}>
        <Container>
          <GlobalStyle />
          <Content>
            <Picture>
              <img src={picture} alt="" />
            </Picture>
            <ContentBodyWrapper>
              <ContentBody>
                <NameText>Kevin van der Toorn</NameText>
                <FunctionText>Electrical Engineering student</FunctionText>
                <ButtonRow>
                  <ButtonLinkedIn />
                  <ButtonGithub />
                  <ButtonStackOverflow />
                </ButtonRow>
              </ContentBody>
            </ContentBodyWrapper>
          </Content>
        </Container>
      </ThemeProvider>
    );
  }
}
