export const lightTheme = {
  body: '#FFFFFF',
  textPrimary: '#121212',
  textSecondary: '#636363',
  button: '#b9b9b9',
  buttonGithubHover: '#24292e',
};

export const darkTheme = {
  body: '#131415',
  textPrimary: '#DBDBDB',
  textSecondary: '#999999',
  button: '#878787',
  buttonGithubHover: '#FFFFFF',
};